<template>
  <div class="footer">
    <div class="flex left agreement">
      <a href="/userAgreement.html">{{ $t("用户协议") }}</a>
      <a href="/privacy.html">
        {{ $t("隐私协议") }}
      </a>
      <a href="/accountDeletionRequest.html">
        {{ $t("账户删除声明") }}
      </a>
      <a href="/cookie.html">
        {{ $t("Cookie声明") }}
      </a>
    </div>
    <div class="left">Copyright @2024 Smart Pay</div>
  </div>
</template>
<script>
export default {
  name: "footerPage",
  data() {
    return {
      isShow: false,
    };
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 64px;
  color: #fff;
  background: #121212;
  .left {
    font-family: "MiSans-Demibold";
    font-size: 12px;
    font-weight: 600;
    line-height: 13.26px;
    text-align: center;
    color: #fff;
    opacity: 0.3;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #ffffff33;
    p {
      cursor: pointer;
      &:first-child {
        margin-right: 48.75px;
      }
    }
  }
  .agreement {
    a {
      margin-right: 5px;
    }
  }
}
</style>
