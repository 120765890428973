<template>
  <div class="flex items-center justify-between header">
    <div class="left">SMART PAY</div>
    <div @click="isShow = !isShow">
      <i class="el-icon-s-fold"></i>
    </div>
    <div
      class="rightDrawer"
      v-show="isShow"
      :style="{ height: screenHeight - 64 + 'px' }"
    >
      <el-button type="primary" class="customBtn loginBtn" @click="toLogin">{{
        $t("登录")
      }}</el-button>
      <el-button type="info" class="customBtn registerBtn" @click="toLogin">{{
        $t("注册")
      }}</el-button>
      <!-- <el-button
        type="primary"
        class="customBtn downloadBtn"
        @click="download"
        >{{ $t("立即下载") }}</el-button
      > -->
      <el-button
        type="primary"
        class="customBtn createCardBtn"
        @click="toLogin"
        >{{ $t("申请卡片") }}</el-button
      >
      <div class="languageBox">
        <div class="langBox">
          <img
            src="@/assets/imgs/language.png"
            alt=""
            class="language"
            @click="isShowList = !isShowList"
          />
        </div>
        <div class="list" v-show="isShowList">
          <li
            v-for="(lang, index) in langs"
            :key="index"
            @click="handleCommand(lang)"
            :class="curLanguage == lang.value ? 'languageActive' : ''"
          >
            {{ lang.text }}
          </li>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "header-page",
  data() {
    return {
      langs: [
        { text: "English", value: "en" },
        { text: "中文", value: "zh" },
        { text: "日本语", value: "jp" },
      ],
      curLanguage: localStorage.getItem("accept_language"),
      currentLan: "",
      screenHeight: window.innerHeight, // 初始化为当前高度
      isShow: false,
      isShowList: false,
    };
  },
  methods: {
    handleCommand(command) {
      localStorage.setItem("accept_language", command.value);
      this.$i18n.locale = command.value;
      this.currentLan = this.langs.find(
        (item) => item.value === command.value
      )?.text;
      window.location.reload();
    },
    updateHeight() {
      this.screenHeight = window.innerHeight; // 更新高度
    },
    toLogin() {
      window.open("https://card.insmartpay.com");
    },
    download() {
      window.location.href =
        "https://d1qv1lnrgfy0u7.cloudfront.net/apk/smartpay_official.apk";
    },
  },
  mounted() {
    // 监听窗口大小变化
    window.addEventListener("resize", this.updateHeight);
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener("resize", this.updateHeight);
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 375px;
  height: 64px;
  padding: 20px 17px;
  color: #fff;
  background-color: #000000;
  .left {
    font-family: "MiSans-Bold";
    font-size: 22px;
    line-height: 35.67px;
    text-align: left;
  }
}
.rightDrawer {
  position: absolute;
  top: 64px;
  right: 0;
  width: 160px;
  background-color: #121212;
  // z-index: 999;
  padding: 32px 20px 42px;
  .loginBtn {
    width: 120px !important;
    height: 38px;
    border-radius: 60px;
    color: #fff;
  }
  .registerBtn {
    width: 120px !important;
    height: 38px;
    color: #9b6d09;
    margin-top: 20px;
    margin-left: 0 !important;
  }
  .downloadBtn {
    width: 120px !important;
    white-space: wrap;
    position: absolute;
    bottom: 170px;
  }
  .createCardBtn {
    width: 120px !important;
    position: absolute;
    bottom: 112px;
  }

  .languageBox {
    position: absolute;
    bottom: 44px;
    margin-left: 20px;
    cursor: pointer;
    margin-left: 30px;
    .language {
      width: 20px;
      height: 20px;
    }
    .list {
      list-style: none;
      position: absolute;
      left: -50%;
      bottom: 46px;
      cursor: pointer;
      padding: 20px 20px 0;
      border-radius: 11px;
      background: #202020;
      border: 1px solid #363636;
      li {
        color: #fff;
        font-family: "MiSans-Regular";
        font-size: 14px;
        font-weight: 400;
        line-height: 18.56px;
        text-align: left;
        margin-bottom: 12px;
      }
    }
    text-align: center;
  }
  .active {
    background-color: transparent;
    background: linear-gradient(180deg, #846c37 0%, #5f4b1f 100%);
    /* 渐变颜色 */
    -webkit-background-clip: text;
    /* 仅剪切文本部分的背景 */
    -webkit-text-fill-color: transparent;
    /* 文字填充颜色透明 */
    // display: inline-block;
    /* 确保文本能够应用背景剪切 */
    font-family: "MiSans-Demibold";
    font-size: 14px;
    font-weight: 600;
    line-height: 18.56px;
    text-align: left;
  }
}
</style>
